import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { UserdataService } from '@services/userdata/userdata.service';
import { TranslateService } from '@ngx-translate/core';
import { SubscriberService } from '@services/subscriber/subscriber.service';
import { IFiscalYearPreference } from '@modules/subscriber/settings/components/date-select/date-select.component';


@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(
    private logger: NGXLogger,
    private userData: UserdataService,
    protected translate: TranslateService,
    private subscriber: SubscriberService
  ) {
  }

  /**
   * sortArray - sort an array of objects by various properties
   *
   * @param list - a reference to  list of objects to sort
   * @param columns[] - a reference to a list of column objects to use for sort criteria
   * @param  columns.name - the name of a property to sort using
   * @param  columns.function - an optional reference to a function to use to interpret the data
   */

  sortArray(list: any, columns: any) {
    let ret = [];
    if (list && typeof list === 'object' && Array.isArray(list) && list.length) {
      // it is a list and there are items in it
      ret = list.sort(function (a, b) {
        let winner = 0;
        if (columns && typeof columns === 'object' && Array.isArray(columns) && columns.length) {
          $.each(columns, function (idx, ref) {
            if (ref.name && a.hasOwnProperty(ref.name) && b.hasOwnProperty(ref.name)) {
              // we can sort by this
              let ia = a[ref.name];
              let ib = b[ref.name];
              if (ref.hasOwnProperty('function') && typeof ref.function === 'function') {
                ia = ref.function(ia);
                ib = ref.function(ib);
              }
              if (ia > ib) {
                winner = 1;
                return false;
              }
              if (ib > ia) {
                winner = -1;
                return false;
              }
              winner = 0;
            }
          });
        } else {
          this.logger.error('No columns defined no idea how to sort');
        }
        return winner;
      });
    }
    return ret;
  }

  toLowerCase(item) {
    if (item && typeof item === 'string') {
      return item.toLowerCase();
    } else {
      return item;
    }
  }

  public makeArray(item: any, func?: Function) {
    if (item === undefined) {
      return [];
    }
    if (typeof (item) === 'string') {
      if (func !== undefined) {
        return [func(item)];
      } else {
        return [item];
      }
    } else {
      if (func !== undefined) {
        const r = [];
        $.each(item, (i, v) => {
          r.push(func(v));
        });
        item = r;
      }
      return item;
    }
  }

  /**
   * formatFullTime - return the time in Year/month/date hour:second
   *
   * @Param unixTime - time stamp of observation.
   * @param none - optional string that will be used if the time is zero
   * @param omitYear - optional boolean indicating the year should be omitted.  Defaults to false
   * @param omitTime - optional boolean indicating the time should be omitted.  Defaults to false
   */
  public dateTimeFormat(unixTime, none: string = null, omitYear: boolean = false, omitTime: boolean = false, useUTC : boolean = false) {
    if (unixTime) {
      // moment.locale( this.translate.getDefaultLang());
      if (omitTime) {
        if (useUTC) {
          return moment(unixTime * 1000).utc().format('MMM\xa0DD,\xa0YYYY');
        } else {
          return moment(unixTime * 1000).format('MMM\xa0DD,\xa0YYYY');
        }
      }
      if (this.userData.getUnits('time') === '24h') {
        if (0 && omitYear) {
          return moment(unixTime * 1000).format('MMM\xa0DD\xa0HH:mm');
        } else {
          return moment(unixTime * 1000).format('MMM\xa0DD,\xa0YYYY\xa0HH:mm');
        }
      } else {
        if (0 && omitYear) {
          return moment(unixTime * 1000).format('MMM\xa0DD\xa0h:mm\xa0A');
        } else {
          return moment(unixTime * 1000).format('MMM\xa0DD,\xa0YYYY\xa0h:mm\xa0A');
        }
      }
    } else {
      if (none !== null) {
        return none;
      } else {
        return 'None';
      }
    }
  }

  public locationDateTimeFormat(timezone: string, unixTime: number, none: string = 'None'): string {
    if (unixTime) {
      let t;
      if (timezone) {
        t = moment.tz(unixTime * 1000, timezone);
      } else {
        t = moment(unixTime * 1000);
      }

      if (this.userData.getUnits('time') === '24h') {
        return t.format('MMM\xa0DD,\xa0YYYY\xa0HH:mm z');
      } else {
        return t.format('MMM\xa0DD,\xa0YYYY\xa0h:mm\xa0A z');
      }
    } else {
      return none;
    }
  }

  public timeFormat(theTime, none?) {
    // moment.locale( this.translate.getDefaultLang());
    if (theTime) {
      if (theTime < 10000000000) {
        theTime *= 1000;
      }
      if (this.userData.getUnits('time') === '24h') {
        return moment(theTime).format('HH:mm');
      } else {
        return moment(theTime).format('h:mm\xa0A');
      }
    } else {
      if (none !== undefined) {
        return none;
      } else {
        return 'None';
      }
    }
  }

  public convertToSeconds(number: number, unit: string) {
    if (number === undefined || !number) {
      return 0;
    }
    if (unit === undefined) {
      return number;
    }
    if (unit === 'weeks') {
      return number * 604800;
    } else if (unit === 'days') {
      return number * 86400;
    } else if (unit === 'hours') {
      return number * 3600;
    } else if (unit === 'minutes') {
      return number * 60;
    } else {
      return number;
    }
  }


  public toMilliseconds(secs): number {
    if (secs === undefined || secs < 0) {
      return null;
    }
    // tslint:disable-next-line:triple-equals
    if (secs == 0) {
      return 0;
    }
    if (secs < 10000000000) {
      secs = secs * 1000;
    }
    return +secs;
  }

  public toSeconds(secs): number {
    if (secs === undefined || secs < 0) {
      return null;
    }
    // tslint:disable-next-line:triple-equals
    if (secs == 0) {
      return 0;
    }
    if (('' + secs).length === 13) {
      secs = _.round(secs / 1000, 0);
    }
    return +secs;
  }

  public makeRGBa(hex: string, opacity: number = 1.0): string {
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
  }

  public tzDelta(timezone: string, start: number): number {
    if (start) {
      start = this.toMilliseconds(start);
    } else {
      start = Date.now();
    }
    const temp  = moment(start).format('YYYY-MM-DD HH:mm:SS');
    // this will be created in the target timezone using the parsed string as the source
    const s = moment.tz(temp, timezone).valueOf();
    // convert to a string
    return start - s;
  }
  /**
   *
   * @param period - the name of a period for which to calculate a timespan.  Names include all, today, yesterday, 7days,
   * 180days, 90days, 28days, 30days, 365days, thisweek, lastweek, thismonth, lastmonth, thisquarter, lastquarter, thisyear, lastyear
   *
   */
  public timespan(period: string = 'all', ending?: number, timezone?: string): { startTime: number; endTime: number } {

    const ret = {startTime: 0, endTime: Date.now()};

    let s = moment();
    if (timezone && _.isString(timezone) && timezone !== '') {
      // operate on a timezone
      // if there is an ending use it to calibrate
      if (ending) {
        if (!_.isNumber(ending)) {
          s = moment.tz(ending, timezone);
        } else {
          // ending is a number. We need to map it into a string then parse it
          // in the context of the timezone specified
          const end = ending < 10000000000 ? ending * 1000 : ending;
          // This will be parsed in the local timezone
          const temp_end  = moment(end).format('YYYY-MM-DD HH:mm:SS');
          // this will be created in the target timezone using the parsed string as the source
          s = moment.tz(temp_end, timezone);
          // use this timezone's endtime to bound the range instead of the local timezone's
          ret.endTime = s.unix() * 1000;
        }
      } else {
        // there is no ending
        const temp_start  = s.format('YYYY-MM-DD HH:mm:SS');
        s = moment.tz(temp_start, timezone);
      }
    } else {
      if (ending) {
        ret.endTime = ending;
        if (ret.endTime < 10000000000) {
          ret.endTime *= 1000;
        }
        s = moment(ret.endTime);
      }
    }

    // moment.locale( this.translate.getDefaultLang());

    let t0 = '' + ret.startTime;
    let t1 = '' + s.endOf('day').format('x');

    if (period === 'today' || period === 'day') {
      // start at midnight
      t0 = s.startOf('day').format('x');
    } else if (period === 'allday') {
      t0 = s.startOf('day').format('x');
      t1 = s.endOf('day').format('x');
    } else if (period === 'yesterday') {
      t1 = s.subtract(1, 'day').endOf('day').format('x');
      t0 = s.startOf('day').format('x');
    } else if (period === '7days') {
      t0 = s.subtract(6, 'days').startOf('day').format('x');
    } else if (period === '14days') {
      t0 = s.subtract(13, 'days').startOf('day').format('x');
    } else if (period === '28days') {
      t0 = s.subtract(27, 'days').startOf('day').format('x');
    } else if (period === '30days') {
      t0 = s.subtract(29, 'days').startOf('day').format('x');
    } else if (period === '60days') {
      t0 = s.subtract(59, 'days').startOf('day').format('x');
    } else if (period === '90days') {
      t0 = s.subtract(89, 'days').startOf('day').format('x');
    } else if (period === '180days') {
      t0 = s.subtract(179, 'days').startOf('day').format('x');
    } else if (period === '365days') {
      t0 = s.subtract(364, 'days').startOf('day').format('x');
    } else if (period === 'thisweek' || period === 'week') {
      t0 = s.startOf('week').format('x');
      t1 = s.endOf('week').format('x');
    } else if (period === 'lastweek') {
      t0 = s.subtract(1, 'week').startOf('week').format('x');
      t1 = s.endOf('week').format('x');
    } else if (period === 'thismonth' || period === 'month') {
      t0 = s.startOf('month').format('x');
      t1 = s.endOf('month').format('x');
    } else if (period === 'lastmonth') {
      t0 = s.subtract(1, 'month').startOf('month').format('x');
      t1 = s.endOf('month').format('x');
    } else if (period === 'thisquarter' || period === 'quarter') {
      t0 = s.startOf('quarter').format('x');
      t1 = s.endOf('quarter').format('x');
    } else if (period === 'lastquarter') {
      t0 = s.subtract(1, 'quarter').startOf('quarter').format('x');
      t1 = s.endOf('quarter').format('x');
    } else if (period === 'thisyear') {
      t0 = s.startOf('year').format('x');
      t1 = s.endOf('year').format('x');
    } else if (period === 'lastyear') {
      t0 = s.subtract(1, 'year').startOf('year').format('x');
      t1 = s.endOf('year').format('x');
    } else if (period === 'thisfiscalquarter') {
      t0 = this.getFiscalQuarterStart(s).format('x');
      t1 = this.getFiscalQuarterStart(s).add(3, 'month').format('x');
    } else if (period === 'lastfiscalquarter') {
      t0 = this.getFiscalQuarterStart(s).subtract(3, 'month').format('x');
      t1 = this.getFiscalQuarterStart(s).format('x');
    } else if (period === 'thisfiscalyear') {
      t0 = this.getFiscalYearStart(s).format('x');
      t1 = this.getFiscalYearStart(s).add(1, 'year').format('x');
    } else if (period === 'lastfiscalyear') {
      t0 = this.getFiscalYearStart(s).subtract(1, 'year').format('x');
      t1 = this.getFiscalYearStart(s).format('x');
    }
    ret.startTime = parseInt(t0, 10);
    ret.endTime = parseInt(t1, 10);

    return ret;
  }

  public getFiscalYearStart(s: moment.Moment) {
    const fiscalYear: IFiscalYearPreference = this.subscriber.subInfo.preferences.fiscalYear;
    const monthPosition = moment.months().indexOf(fiscalYear.month);
    const start = s.clone().set('month', monthPosition).set('date', fiscalYear.day);

    if (start.isAfter(s)) {
      start.subtract(1, 'year');
    }

    return start;
  }

  public getFiscalQuarterStart(s: moment.Moment): moment.Moment  {
    const quarterStart = this.getFiscalYearStart(s);

    for (let i = 0; i < 4; i++) {
      quarterStart.add(3, 'month');
      if (quarterStart.isAfter(s)) {
        return quarterStart.subtract(3, 'month');
      }
    }

    return quarterStart;
  }

  public secsToDuration(totalSeconds, trimHours: boolean = false, timeFormat: string = 'mm:ss'): string {
    const duration = moment.duration(+totalSeconds, 'seconds');
    const baseMomentInstance = moment(duration.asMilliseconds());

    let hours: number | string = Math.floor(duration.asHours());

    if (!trimHours) {
      hours = hours < 10 ? `0${hours}` : hours;
    }

    return `${hours}:${baseMomentInstance.format(timeFormat)}`;
  }

  public durationToSecs(totalSeconds): number {
    const timeItems = totalSeconds.split(':');
    const hoursToSecs = +timeItems[0] * 3600;
    const minsToSecs = +timeItems[1] * 60;
    const secs = +timeItems[2];

    const result = hoursToSecs + minsToSecs + secs;

    return result;
  }

  /**
   * @param formData - provided form data
   * @param {string[]} names - names that are used for extracting values from formData
   * @param {string[]} targetNames - names that are used for translation key property in output data
   * @returns the provided form data with 'translations' object
   */

  public encodeTranslations(formData: any, names: string[], targetNames: string[]): any {
    const userLanguages: string[] = this.subscriber.getLanguages(true);
    const translations: { [key: string]: any } = {};

    _.each(userLanguages, (userLanguage: string) => {
      _.each(names, (name: string, nameIndex: number) => {
        const fieldProperty = `${name}_${userLanguage}`;

        if (formData.hasOwnProperty(fieldProperty)) {
          const targetName: string = targetNames[nameIndex];

          if (_.isEmpty(translations[targetName])) {
            translations[targetName] = [];
          }

          translations[targetName].push({
            language: userLanguage,
            value: formData[fieldProperty]
          });
        }
      });
    });

    if (!_.isEmpty(translations)) {
      formData.translations = JSON.stringify(translations);
    }

    return formData;
  }

  /**
   * @param formData - provided form data
   * @param {string[]} names - names that are used for creating form properties
   * @param {string[]} targetNames - names that are used for extracting values from 'translations' object
   */

  public decodeTranslations(formData: any = {}, names: string[], targetNames: string[]): void {
    if (!_.isEmpty(formData.translations)) {
      _.each(targetNames, (targetName: string, targetNameIndex: number) => {
        const translations = _.get(formData, 'translations');
        const translationsByFieldName: any[] = _.isArray(translations) ? translations : _.get(translations, targetName, []);

        _.each(translationsByFieldName, (translation: any) => {
          const formFieldKey = `${names[targetNameIndex]}_${translation.language}`;
          formData[formFieldKey] = translation.value;
        });
      });
    }
  }

  public async iterateWithDelay(array: any[], itemHandler: (item) => void, delay: number = 100, cutoff: number = 5000) {
    for (const [index, item] of array.entries()) {
      if (index % cutoff === 0) {
        await new Promise((resolve) => {
          setTimeout(() => resolve(item), delay);
        });
      }

      itemHandler(item);
    }
  }

  public static charAtIdx(str, i) {
    const code = str.charCodeAt(i);

    if (Number.isNaN(code)) {
      return ""; // Position not found
    }
    if (code < 0xd800 || code > 0xdfff) {
      return str.charAt(i);
    }

    // High surrogate (could change last hex to 0xDB7F to treat high private
    // surrogates as single characters)
    if (0xd800 <= code && code <= 0xdbff) {
      if (str.length <= i + 1) {
        return "";
      }
      const next = str.charCodeAt(i + 1);
      if (next < 0xdc00 || next > 0xdfff) {
        return "";
      }
      return str.charAt(i) + str.charAt(i + 1);
    }

    // Low surrogate (0xDC00 <= code && code <= 0xDFFF)
    if (i === 0) {
      return "";
    }

    const prev = str.charCodeAt(i - 1);

    // (could change last hex to 0xDB7F to treat high private surrogates
    // as single characters)
    if (prev < 0xd800 || prev > 0xdbff) {
      return "";
    }

    // Return the next character instead (and increment)
    return str.charAt(i + 1);
  }

}
